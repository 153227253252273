<template>
	<v-card outlined class="border">
		<v-card-title class="orange lighten-4">
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2">mdi-chart-multiple</v-icon>
			<div class="my-auto fw-600 text-uppercase fs-18">Projects Overview</div>
			<v-spacer></v-spacer>
			<template v-if="['admin', 'manager', 'master'].includes(checkAdminOrManager())">
				<v-menu left bottom offset-y :max-width="200" :max-height="300" content-class="z-index-11">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							height="35"
							min-height="35"
							color="blue lighten-5"
							class="mr-5"
							depressed
							v-bind="attrs"
							v-on="on"
						>
							<span class="fs-16 text-capitalize text-truncate" style="width: 140px">{{
								dActiveProjectUser.display_name
							}}</span>
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in usersList"
							:key="index"
							@click="filterByUser(item)"
							:class="[
								'py-1',
								{
									'grey lighten-3': JSON.stringify(dActiveProjectUser) == JSON.stringify(item),
								},
							]"
						>
							<v-list-item-avatar size="30" class="my-1 mr-2">
								<v-img
									:src="item.profile_img ? item.profile_img : $assetURL(`media/users/blank.png`)"
								></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title style="min-width: auto">{{ item.display_name }}</v-list-item-title>
								<v-list-item-subtitle class="mb-1 text-uppercase" v-if="item.total_project">
									<span class="fw-700">{{ item.total_project }}</span> project</v-list-item-subtitle
								>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						height="35"
						min-height="35"
						class="mr-5"
						color="orange lighten-5"
						depressed
						v-bind="attrs"
						v-on="on"
					>
						<span class="fs-16 text-capitalize px-3">{{ dActiveProjectType.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in projectTypeFilterList"
						:key="index"
						@click="filterByType(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveProjectType.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn height="35" min-height="35" color="orange lighten-5" depressed v-bind="attrs" v-on="on">
						<span class="fs-16 text-capitalize px-3">{{ dActiveProjectDuration.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in projectDurationFilterList"
						:key="index"
						@click="filterByDuration(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveProjectDuration.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>
		<div style="height: 50px" class="orange lighten-4"></div>
		<v-layout class="px-4" style="margin-top: -50px">
			<v-flex
				class="border py-2 px-5 mr-5 blue lighten-4 blue--text rounded position-relative overflow-hidden"
			>
				<div class="fw-600" style="font-size: 2.5rem">
					<template v-if="pageLoading">
						<v-progress-circular indeterminate size="24" width="2" color="blue"></v-progress-circular>
					</template>
					<template v-else>
						{{ getTotal.new }}
					</template>
				</div>
				<v-icon class="d-summery-icon" color="blue" size="5rem">mdi-chart-bell-curve</v-icon>
				<div class="font-size-h6">New Projects</div>
			</v-flex>
			<v-flex
				class="border py-2 px-5 mr-5 green lighten-4 green--text rounded position-relative overflow-hidden"
			>
				<div class="fw-600" style="font-size: 2.5rem">
					<template v-if="pageLoading">
						<v-progress-circular indeterminate size="24" width="2" color="blue"></v-progress-circular>
					</template>
					<template v-else>
						{{ getTotal.recurring }}
					</template>
				</div>
				<v-icon class="d-summery-icon" color="green" size="5rem">mdi-chart-bell-curve</v-icon>
				<div class="font-size-h6">Recurring Project</div>
			</v-flex>
			<v-flex
				class="border py-2 px-5 red lighten-4 red--text rounded position-relative overflow-hidden"
			>
				<div class="fw-600" style="font-size: 2.5rem">
					<template v-if="pageLoading">
						<v-progress-circular indeterminate size="24" width="2" color="red"></v-progress-circular>
					</template>
					<template v-else>
						{{ getTotal.cancelled }}
					</template>
				</div>
				<v-icon class="d-summery-icon" color="red" size="5rem">mdi-chart-bell-curve</v-icon>
				<div class="font-size-h6">Cancelled Project</div>
			</v-flex>
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<div>
				<apexchart
					type="line"
					height="450"
					:options="getChartOptions"
					:series="getSeries"
					width="100%"
				></apexchart>
			</div>
		</div>
	</v-card>
</template>
<script>
import { GET_PROJECTS, SET_ACTIVE_FILTER } from "@/core/services/store/dashboard.module.js";
import { mapGetters } from "vuex";
import { filter } from "lodash";
import moment from "moment";
export default {
	name: "ProjectStatus",
	data() {
		return {
			pageLoading: false,
			projectDurationFilterList: [
				{ text: "This Week", value: "this_week" },
				{ text: "This Month", value: "this_month" },
				{ text: "This Quarter", value: "this_quarter" },
				{ text: "This Year", value: "this_year" },
			],
			projectTypeFilterList: [
				{ text: "All Type", value: "all" },
				{ text: "Software", value: "software" },
				{ text: "Seo", value: "seo" },
			],
		};
	},
	methods: {
		filterByDuration(item) {
			if (this.dActiveProjectDuration.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_project_duration",
					value: item,
				});
				this.getProjects();
			}
		},
		filterByType(item) {
			if (this.dActiveProjectType.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_project_type",
					value: item,
				});
				this.getProjects();
			}
		},
		filterByUser(item) {
			if (this.dActiveProjectUser.id != item.id) {
				this.$store.commit(SET_ACTIVE_FILTER, { key: "active_project_user", value: item });
				this.getProjects();
			}
		},
		getProjects() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET_PROJECTS, {
					filter: this.dActiveProjectDuration.value,
					product_type: this.dActiveProjectType.value,
					user_filter: this.dActiveProjectUser.id ? this.dActiveProjectUser.id : null,
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters([
			"localDB",
			"dProjects",
			"dActiveProjectDuration",
			"dActiveProjectType",
			"dActiveProjectUser",
		]),
		usersList() {
			let _users = this.localDB("allUsers", []);
			_users = filter(_users, (user) => user.users_type == "sales");
			_users.unshift({
				id: 0,
				display_name: "All Users",
				profile_img: this.$assetURL("media/users/blank.png"),
				total_project: 0,
			});
			return _users;
		},
		getSeries() {
			const _project = this.dProjects;
			let _new_project = [];
			let _recurring_project = [];
			let _cancelled_project = [];
			let _labels = [];
			Object.keys(_project).forEach(function (key) {
				_new_project.push(_project[key].draft_projects);
				_recurring_project.push(_project[key].renewed_projects);
				_cancelled_project.push(_project[key].cancelled_projects);
				_labels.push(key);
			});
			const _series = [
				{
					name: "New Projects",
					type: "column",
					data: _new_project,
				},
				{
					name: "Recurring Project",
					type: "area",
					data: _recurring_project,
				},
				{
					name: "Cancelled Project",
					type: "column",
					data: _cancelled_project,
				},
			];
			return _series;
		},
		getTotal() {
			const _project = this.dProjects;
			let _draft_projects = 0;
			let _renewed_projects = 0;
			let _cancelled_projects = 0;
			Object.keys(_project).forEach(function (key) {
				_draft_projects = _draft_projects + _project[key].draft_projects;
				_renewed_projects = _renewed_projects + _project[key].renewed_projects;
				_cancelled_projects = _cancelled_projects + _project[key].cancelled_projects;
			});
			return {
				new: _draft_projects,
				recurring: _renewed_projects,
				cancelled: _cancelled_projects,
			};
		},
		getChartOptions() {
			const _project = this.dProjects;
			const _duration = this.dActiveProjectDuration.value;
			let _labels = [];
			Object.keys(_project).forEach(function (key) {
				let _formatted = moment(key, "YYYY-MM-DD").format("DD MMM");
				if (_duration == "this_year") {
					_formatted = moment(key, "YYYY-MM-DD").format("MMM YY");
				}
				_labels.push(_formatted);
			});
			return {
				chart: {
					height: 450,
					type: "line",
					stacked: false,
					zoom: {
						enabled: false,
					},
				},
				stroke: {
					width: [0, 2, 5],
					curve: "smooth",
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [1],
				},
				plotOptions: {
					bar: {
						columnWidth: "30%",
					},
				},
				colors: ["#2196F3", "#4CAF50", "#F44336"],
				fill: {
					opacity: [0.85, 0.25, 1],
					gradient: {
						inverseColors: false,
						shade: "light",
						type: "vertical",
						opacityFrom: 0.85,
						opacityTo: 0.55,
						stops: [0, 100, 100, 100],
					},
				},
				legend: {
					show: true,
					fontSize: "13px",
					fontFamily: "inherit",
					fontWeight: 500,
					offsetY: 20,
					itemMargin: {
						horizontal: 15,
						vertical: 15,
					},
				},
				labels: _labels,
				markers: {
					size: 0,
				},
				/* xaxis: {
					type: "datetime",
					labels: {
						datetimeFormatter: {
							year: "yyyy",
							month: "MMM yyyy",
							day: "dd MMM",
							hour: "HH:mm",
						},
					},
				}, */
				yaxis: {
					stepSize: 1,
					min: 0,
					max: function (val) {
						if (val <= 0) {
							return 5;
						}
						return Math.ceil(val);
					},
					labels: {
						formatter: function (val) {
							return Math.ceil(val);
						},
					},
					title: {
						text: "Projects",
						style: {
							fontFamily: "inherit",
							fontSize: 13,
							fontWeight: 500,
						},
					},
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0);
							}
							return y;
						},
					},
				},
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.project-stats-count {
	tr:not(.no-border) {
		th,
		td {
			padding: 4px 8px;
			// border:1px solid grey
		}
	}
}
.right-graphics {
	position: absolute;
	right: 10px;
	bottom: 10px;
	width: 100px;
	opacity: 0.3;
}
</style>
